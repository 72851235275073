<template>
  <v-card>
    <v-row>
      <!-- filter by search  -->
      <v-col cols="4">
        <v-card-title>
          <v-text-field
            append-icon="mdi-magnify"
            label="Pencarian"
            @change="searchItem"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-col>
      <v-col cols="4">
        <v-card-title>
          <v-select
            :items="cabang"
            item-text="branch_name"
            item-value="id"
            clearable
            label="Pilih Cabang"
            @change="saveBranch"
          ></v-select>
        </v-card-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4">
        <v-card-title>
          <v-dialog
            ref="dialog"
            v-model="dateMenu"
            :return-value.sync="date"
            persistent
            width="290px"
            @click:outside="dateMenu = false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange"
                label="Checkout Date"
                prepend-icon="mdi-calendar"
                readonly
                :rules="[dateRangeRules]"
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              scrollable
              range
              color="myPrimary"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="saveDate"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="right">
        <v-btn
          small
          color="blue white--text"
          class="mr-2 mb-3"
          :loading="thisLoadingExport"
          @click="clickOnLoadExport"
        >
          <v-icon left> mdi-file-export</v-icon> Export PDF
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersEmas"
      :items="dataEmas"
      v-model="selectedEmas"
      :sort-by="sortBy"
      class="elevation-1"
      :loading="tableLoading"
      @pagination="paginate"
      loading-text="Loading... Please wait"
      :footer-props="footerProps"
      :server-items-length="dataPagination.itemsLength"
    >
      <template v-slot:top>
        <!-- Modal Preview Image  -->
        <v-dialog v-model="dialogImage" max-width="550px">
          <ImageModal :data="showedData" @closePreview="closeImage" />
        </v-dialog>
        <!-- Modal Preview Edit  -->
        <v-dialog v-model="dialogEdit" max-width="800px">
          <EditGoldModal
            :data="showedData"
            @closeEdit="closeEdit"
            @saveEdit="saveEdit($event)"
          />
        </v-dialog>
        <!-- Modal Preview Delete  -->
        <v-dialog v-model="dialogDelete" max-width="550px">
          <StandartModal
            :text="'Apakah anda yakin ingin menghapus item checkout ?'"
            @dialogFalse="closeDelete"
            @dialogTrue="confirmDelete"
          />
        </v-dialog>
      </template>

      <template v-slot:[`item.photo_product`]="{ item }">
        <div class="p-2" @click="previewImage(item)">
          <v-img
            :src="item.photo_product"
            :alt="item.rfid"
            width="40px"
            height="40px"
            contain
          ></v-img>
        </div>
      </template>

      <template v-slot:[`item.weight`]="{ item }">
        <div>{{ parseGram(item.weight) }}</div>
      </template>

      <template v-slot:[`item.total_price`]="{ item }">
        <div>{{ parseRupiah(item.total_price) }}</div>
      </template>

      <template v-slot:[`item.date_order`]="{ item }">
        <div>{{ parseDate(item.date_order) }}</div>
      </template>

      <template v-slot:[`item.price_gold`]="{ item }">
        <div>{{ parseRupiah(item.price_gold) }}</div>
      </template>

      <template v-slot:[`item.gold_profit`]="{ item }">
        <div>{{ parseGram(item.gold_profit) }}</div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="tableEdit" small class="mr-2" @click="previewEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="tableDelete"
          small
          class="mr-2"
          @click="previewDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:[`body.append`]>
        <tr>
          <td colspan="12">
            <v-row class="pa-4">
              <v-col
                >Total Keuntungan :
                {{
                  totalKeuntunganEmas
                    ? parseGram(totalKeuntunganEmas)
                    : parseGram(0)
                }}</v-col
              >
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ImageModal from "../Modal/ImageModal";
import EditGoldModal from "../Modal/EditGoldCheckoutModal";
import StandartModal from "../Modal/StandartModal";
import dataService from "../../services/data.service";
import moment from "moment";

export default {
  props: [
    "dataPagination",
    // table action
    "sortBy",
    "headersEmas",
    "dataEmas",
    "tableLoading",

    // inside table action
    "tableEdit",
    "tableDelete",
    "totalKeuntunganEmas",
  ],

  components: {
    ImageModal,
    EditGoldModal,
    StandartModal,
  },

  computed: {
    dateRange: {
      get: function () {
        return this.date;
      },
      set: function (item) {
        let date1 = new Date(item[0]);
        let date2 = new Date(item[1]);

        if (date1 > date2) {
          this.date = [item[1], item[0]];
        } else {
          this.date = item;
        }
        return this.date;
      },
    },
    footerProps() {
      let data = {
        pagination: this.dataPagination,
        "items-per-page-options": [5, 10, 15, 20, 25],
      };
      return data;
    },
  },

  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      // filter by search
      searchEmas: "",
      cabang: [],
      cabangSelected: 0,
      product: "gold",
      dateMenu: false,
      // date: [
      //   new Date(Date.now() -  - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      // ],
      date: [],

      //Export PDF
      thisLoadingExport: false,

      // table action
      selectedEmas: [],
      editedIndex: -1,
      showedData: {},

      // dialog on table
      dialogImage: false,
      dialogEdit: false,
      dialogDelete: false,
    };
  },

  watch: {
    dialogImage(val) {
      val || this.closeImage();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    saveDate() {
      this.$refs.dialog.save(this.date);
      this.paginate(this.pagination);
    },
    saveBranch(value) {
      value = value == null ? 0 : value;
      this.cabangSelected = value;
      this.paginate(this.pagination);
    },
    searchItem(v) {
      this.searchEmas = v;
      this.paginate(this.pagination);
    },
    paginate(obj) {
      let params = {
        per_page: obj.itemsPerPage,
        page: obj.page,
      };
      if (this.searchEmas) {
        params["search"] = this.searchEmas;
      }
      if (this.date) {
        this.date[0] ? (params["date_start"] = this.date[0]) : null;
        this.date[1] ? (params["date_end"] = this.date[1]) : null;
      }
      if (this.cabangSelected) {
        params["branch_id"] = this.cabangSelected;
      }

      this.$emit("filterEmas", params);
    },
    // modal action
    dateRangeRules(item) {
      if (item.length < 2) {
        return "Filter tanggal harus dalam jangka";
      }
      return true;
    },

    // Loading Button Export PDF
    async clickOnLoadExport() {
      this.thisLoadingExport = true;
      const data = {
        search: this.searchEmas,
        date: this.date,
        branch_id: this.cabangSelected,
      };

      await dataService
        .postExportGoldCheckout(data)
        .then((res) => {
          window.open(res.data.url, "_blank");
          setTimeout(() => (this.thisLoadingExport = false), 3000);
        })
        .catch((err) => {
          console.log(err);
          this.thisLoadingExport = false;
        });
    },

    //dialog on table
    previewImage(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogImage = true;
    },
    closeImage() {
      this.dialogImage = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    previewEdit(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogEdit = true;
    },
    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    parseRupiah(price) {
      if (price == null) {
        return "";
      } else {
        return (
          "Rp. " +
          price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
        );
      }
    },
    parseGram(weight) {
      if (weight == null) {
        return "";
      } else {
        return weight + " gr";
      }
    },
    parseDate(date) {
      if (date) {
        return moment(String(date)).format("DD MMMM YYYY hh:mm");
      } else {
        return "";
      }
    },
    previewDelete(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async confirmDelete() {
      let id = this.showedData.checkout_id;
      await dataService
        .deleteCheckout(id)
        .then(() => {
          this.dialogDelete = false;
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //     async saveEdit(gold) {
    //   let data = {
    //     total_price: gold.total_price,
    //   };

    //   await dataService.updateCheckout(this.product, gold.checkout_id, data).then(() => {
    //     location.reload();
    //   });

    //   this.closeEdit();
    // },

    async saveEdit(gold) {
      const id = this.showedData.checkout_id;
      // const date = `${this.showedData.date} ${this.showedData.time}`;
      // const date = `${this.showedData.date_order}`;
      const format_date = "YYYY-MM-DD HH:mm:ss";
      const date = moment(this.showedData.date_order).format(format_date);

      const data = {
        name: gold.product_name,
        berat: gold.weight,
        jenis: gold.type,
        kadar: gold.purity,
        kualitas: gold.quality,
        status: gold.status,
        // cabang_id: gold.branch_id,
        kondisi: gold.conditions,
        catatan: gold.note,
        total_price: gold.total_price,
        customer_name: gold.customer_name,
        image: gold.photo_product,
        date_order: date,
      };

      // const id = this.showedData.id;

      // const data = new FormData();

      // const date = `${this.showedData.date} ${this.showedData.time}`;

      // // data.append("image", gold === null ? "" : gold);

      // data.append(
      //   "name",
      //   this.showedData.product_name === null
      //     ? ""
      //     : this.showedData.product_name
      // );
      // data.append(
      //   "berat",
      //   this.showedData.weight === null ? "" : this.showedData.weight
      // );

      // data.append(
      //   "jenis",
      //   this.showedData.type === null ? "" : this.showedData.type
      // );

      // data.append(
      //   "kadar",
      //   this.showedData.purity === null ? "" : this.showedData.purity
      // );
      // data.append(
      //   "kualitas",
      //   this.showedData.quality === null ? "" : this.showedData.quality
      // );
      // data.append(
      //   "status",
      //   this.showedData.status === null ? "" : this.showedData.status
      // );
      // data.append(
      //   "kondisi",
      //   this.showedData.conditions === null ? "" : this.showedData.conditions
      // );
      // data.append(
      //   "total_price",
      //   this.showedData.total_price === null ? "" : this.showedData.total_price
      // );
      // data.append(
      //   "customer_name",
      //   this.showedData.customer_name === null
      //     ? ""
      //     : this.showedData.customer_name
      // );
      // data.append(
      //   "catatan",
      //   this.showedData.note === null ? "" : this.showedData.note
      // );
      // data.append("date", date === null ? "" : date);

      await dataService
        .updateCheckout(this.product, id, this.showedData.product_id, data)
        .then(() => {
          location.reload();
        });

      this.closeEdit();
    },
    async getCabang() {
      await dataService
        .getCabang()
        .then((res) => {
          this.cabang = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  created() {
    this.getCabang();
  },
};
</script>
