<template>
  <v-card>
    <v-row>
      <!-- filter by search  -->
      <v-col cols="4">
        <v-card-title>
          <v-text-field
            append-icon="mdi-magnify"
            label="Pencarian"
            single-line
            hide-details
            @change="searchItem"
          ></v-text-field>
        </v-card-title>
      </v-col>

      <v-col cols="4">
        <v-card-title>
          <v-select
            :items="cabang"
            item-text="branch_name"
            item-value="id"
            clearable
            label="Pilih Cabang"
            @change="saveBranch"
          ></v-select>
        </v-card-title>
      </v-col>

      <!-- modal action  -->
      <v-col cols="4">
        <!-- modal import emas  -->
        <v-dialog v-if="isImportData" v-model="dialogImporData" width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              color="myPrimary"
              class="mr-2 mb-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-plus</v-icon>Impor Data
            </v-btn>
          </template>
          <ImportModal :GOLD="true" @closeImporData="closeImporData" />
        </v-dialog>

        <!-- modal new emas  -->
        <v-dialog v-if="isNewItem" v-model="dialogNewItem" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="myPrimary white--text"
              class="mr-2 mb-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-plus</v-icon>Tambah Produk
            </v-btn>
          </template>
          <NewGoldModal @closeNewDataEmas="closeNewDataEmas" />
        </v-dialog>

        <!-- modal print emas [ALL]  -->
        <v-dialog v-if="isPrintAll" v-model="dialogPrintAll" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="myPrimary white--text"
              class="mr-2 mb-3"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-printer</v-icon>Cetak Semua
            </v-btn>
          </template>
          <StandartModal
            :text="'Apakah anda yakin print semua ?'"
            @dialogFalse="dialogPrintAll = false"
            @dialogTrue="printAllEmas"
          />
        </v-dialog>

        <!-- modal print emas [PILIHAN]  -->
        <v-dialog
          v-if="isPrintChoosed"
          v-model="dialogPrintChoosed"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="myPrimary white--text"
              class="mr-2 mb-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-printer</v-icon>Cetak Pilihan
            </v-btn>
          </template>
          <StandartModal
            :text="'Apakah anda yakin print pilihan ?'"
            @dialogFalse="dialogPrintChoosed = false"
            @dialogTrue="printSelectedEmas"
          />
        </v-dialog>

        <!-- modal delete emas [PILIHAN]  -->
        <v-dialog
          v-if="isDeleteChoosed"
          v-model="dialogDeleteChoosed"
          max-width="550px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="text-right">
              <v-btn
                small
                color="red white--text"
                class="mr-2 mb-3"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left> mdi-delete</v-icon>Hapus Pilihan
              </v-btn>
            </div>
          </template>
          <StandartModal
            :text="'Apakah anda yakin ingin menghapus produk ?'"
            @dialogFalse="dialogDeleteChoosed = false"
            @dialogTrue="deleteSelectedEmas"
          />
        </v-dialog>

        <!-- Modal Export PDF -->
        <div class="text-right">
          <v-btn
            small
            color="blue white--text"
            class="mr-2 mb-3"
            :loading="thisLoadingExport"
            @click="clickOnLoadExport"
          >
            <v-icon left> mdi-file-export</v-icon> Export PDF
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headersEmas"
      :items="dataEmas"
      :show-select="bulkAction"
      v-model="selectedEmas"
      :sort-by="sortBy"
      class="elevation-1"
      :loading="tableLoading"
      @pagination="paginate"
      loading-text="Loading... Please wait"
      :footer-props="footerProps"
      :server-items-length="dataPagination.itemsLength"
    >
      <template v-slot:top>
        <!-- Modal Preview Image  -->
        <v-dialog v-model="dialogImage" max-width="550px">
          <ImageModal :data="showedData" @closePreview="closeImage" />
        </v-dialog>

        <!-- Modal Preview Detail  -->
        <v-dialog v-model="dialogPreview" max-width="800px">
          <PreviewModal
            :data="showedData"
            @closePreview="closeDetail"
            :dontShowButton="true"
            :emas="true"
          />
        </v-dialog>

        <!-- Modal Preview Edit  -->
        <v-dialog v-model="dialogEdit" max-width="800px">
          <EditGoldModal
            :data="showedData"
            :PRINT="tablePrint"
            @closeEdit="closeEdit"
            @saveEdit="saveEdit($event)"
          />
        </v-dialog>

        <!-- Modal Preview Delete  -->
        <v-dialog v-model="dialogDelete" max-width="550px">
          <StandartModal
            :text="'Apakah anda yakin ingin menghapus produk ?'"
            @dialogFalse="closeDelete"
            @dialogTrue="confirmDelete"
          />
        </v-dialog>

        <!-- Modal Preview Print  -->
        <v-dialog v-model="dialogPrint" max-width="800px">
          <PreviewModal
            :data="showedData"
            @closePreview="closePrint"
            @printItemConfirm="printItemConfirm"
            :emas="true"
          />
        </v-dialog>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <div class="p-2" @click="previewImage(item)">
          <v-img
            :src="item.photo_product"
            :alt="item.rfid"
            width="40px"
            height="40px"
            contain
          ></v-img>
        </div>
      </template>

      <template v-slot:[`item.quality`]="{ item }">
        <div>{{ item.quality }} %</div>
      </template>

      <template v-slot:[`item.price_gold`]="{ item }">
        <!-- <div>{{ parseRupiah(item.capital_price_per_gram) }}</div> -->
        <div>{{ parseRupiah(item.price_gold) }}</div>
      </template>

      <template v-slot:[`item.detail`]="{ item }">
        <v-btn x-small class="mr-2" @click="previewDetail(item)"> Lihat </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="tableEdit" small class="mr-2" @click="previewEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="tablePrint"
          small
          class="mr-2"
          @click="previewPrint(item)"
        >
          mdi-printer
        </v-icon>
        <v-icon
          v-if="tableDelete"
          small
          class="mr-2"
          @click="previewDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import StandartModal from "../Modal/StandartModal";
import ImageModal from "../Modal/ImageModal";
import PreviewModal from "../Modal/PreviewModal";
import EditGoldModal from "../Modal/EditGoldModal";
import NewGoldModal from "../Modal/NewGoldModal";
import ImportModal from "../Modal/ImportModal";

import dataService from "../../services/data.service";

export default {
  props: [
    "dataPagination",
    // show button modal
    "isImportData",
    "isNewItem",
    "isPrintAll",
    "isPrintChoosed",
    "isDeleteChoosed",

    // table action
    "bulkAction",
    "sortBy",
    "headersEmas",
    "dataEmas",
    "tableLoading",

    // inside table action
    "tableEdit",
    "tablePrint",
    "tableDelete",
  ],

  components: {
    StandartModal,
    ImageModal,
    PreviewModal,
    EditGoldModal,
    ImportModal,
    NewGoldModal,
  },

  computed: {
    footerProps() {
      let data = {
        pagination: this.dataPagination,
        "items-per-page-options": [5, 10, 15, 20, 25],
      };
      return data;
    },
  },

  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      dialog: false,
      cabang: [],
      cabangSelected: 0,
      // filter by search
      searchEmas: "",

      // modal action
      dialogImporData: false,
      dialogNewItem: false,
      dialogPrintAll: false,
      dialogPrintChoosed: false,
      dialogDeleteChoosed: false,

      //Export PDF
      thisLoadingExport: false,

      // table action
      selectedEmas: [],
      editedIndex: -1,
      showedData: {},

      // dialog on table
      dialogImage: false,
      dialogPreview: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogPrint: false,
    };
  },

  watch: {
    dialogImage(val) {
      val || this.closeImage();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogPreview(val) {
      val || this.closeDetail();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPrint(val) {
      val || this.closePrint();
    },
  },

  created() {
    this.getCabang();
  },

  methods: {
    async getCabang() {
      await dataService
        .getCabang()
        .then((res) => {
          this.cabang = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    saveBranch(value) {
      value = value == null ? 0 : value;
      this.cabangSelected = value;
      this.paginate(this.pagination);
    },
    searchItem(v) {
      this.searchEmas = v;
      this.paginate(this.pagination);
    },
    paginate(obj) {
      let pagination = {
        per_page: obj.itemsPerPage,
        page: obj.page,
      };
      if (this.searchEmas) {
        pagination["search"] = this.searchEmas;
      }
      if (this.cabangSelected) {
        pagination["branch_id"] = this.cabangSelected;
      }
      this.$emit("paginate", pagination);
    },

    // Loading Button Export PDF
    async clickOnLoadExport() {
      this.thisLoadingExport = false;
      const data = {
        search: this.searchEmas,
        branch_id: this.cabangSelected,
      };
      await dataService
        .postExportGoldInventory(data)
        .then((res) => {
          console.log(res.data.url);
          window.open(res.data.url, "_blank");
          setTimeout(() => (this.thisLoadingExport = false), 3000);
        })
        .catch((err) => {
          console.log(err);
          this.thisLoadingExport = false;
        });
    },

    // modal action
    async deleteSelectedEmas() {
      const data = this.selectedEmas.map((item) => {
        return { id: item.id };
      });

      await dataService.deleteSelectedProduct(data).then(() => {
        location.reload();
      });

      this.dialogDeleteChoosed = false;
    },

    async printAllEmas() {
      const data = this.dataEmas.map((item) => {
        return { id: item.id };
      });

      await dataService.printAllProduct(data).then(() => {
        location.reload();
      });

      this.dialogPrintAll = false;
    },

    async printSelectedEmas() {
      const data = this.selectedEmas.map((item) => {
        return { id: item.id };
      });

      await dataService.printSelectedProduct(data).then(() => {
        location.reload();
      });

      this.dialogPrintChoosed = false;
    },

    closeImporData() {
      this.dialogImporData = false;
    },
    closeNewDataEmas() {
      this.dialogNewItem = false;
    },

    //dialog on table
    previewImage(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogImage = true;
    },
    closeImage() {
      this.dialogImage = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    previewDetail(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogPreview = true;
    },
    closeDetail() {
      this.dialogPreview = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    previewEdit(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogEdit = true;
    },
    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    parseRupiah(price) {
      if (price == null) {
        return "";
      } else {
        return (
          "Rp. " +
          price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
        );
      }
    },

    async saveEdit(imageData) {
      const id = this.showedData.id;

      const data = new FormData();

      const date = `${this.showedData.date} ${this.showedData.time}`;

      data.append(
        "sku",
        this.showedData.sku === null ? "" : this.showedData.sku
      );
      data.append(
        "rfid",
        this.showedData.rfid === null ? "" : this.showedData.rfid
      );
      data.append(
        "name",
        this.showedData.name === null ? "" : this.showedData.name
      );
      data.append("image", imageData === null ? "" : imageData);
      data.append(
        "berat",
        this.showedData.weight === null ? "" : this.showedData.weight
      );
      data.append(
        "jenis",
        this.showedData.type === null ? "" : this.showedData.type
      );
      data.append(
        "kadar",
        this.showedData.purity === null ? "" : this.showedData.purity
      );
      data.append(
        "kualitas",
        this.showedData.quality === null ? "" : this.showedData.quality
      );
      data.append(
        "cabang_id",
        this.showedData.branch_id === null ? "" : this.showedData.branch_id
      );
      if (this.showedData.sub_branch_id != "") {
        data.append(
          "sub_branch_id",
          this.showedData.sub_branch_id === null
            ? ""
            : this.showedData.sub_branch_id
        );
      }
      data.append(
        "kondisi",
        this.showedData.condition === null ? "" : this.showedData.condition
      );
      data.append(
        "catatan",
        this.showedData.note === null ? "" : this.showedData.note
      );
      data.append(
        "status",
        this.showedData.status === null ? "" : this.showedData.status
      );
      data.append("date", date === null ? "" : date);
      data.append(
        "customer_name",
        this.showedData.customer_name === null
          ? ""
          : this.showedData.customer_name
      );
      data.append(
        "total_price",
        this.showedData.total_price === null ? "" : this.showedData.total_price
      );

      await dataService.updateGold(id, data).then(() => {
        location.reload();
      });

      this.closeEdit();
    },

    previewDelete(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async confirmDelete() {
      const id = this.showedData.id;

      await dataService.deleteSingleProduct(id).then(() => {
        this.dialogDelete = false;
        location.reload();
      });
    },

    previewPrint(item) {
      this.editedIndex = this.dataEmas.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogPrint = true;
    },
    closePrint() {
      this.dialogPrint = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async printItemConfirm() {
      const data = [{ id: this.showedData.id }];

      await dataService.printSelectedProduct(data).then(() => {
        location.reload();
      });

      this.closePrint();
    },
  },
};
</script>
